<template>
  <div
    class="register-filter"
  >
    <div
      class="register-inner-container"
      style="justify-content: flex-start"
    >
      <span style="font-size: 20px">Total Working Hours: {{ workingHours }}</span>
    </div>

    <div
      class="register-inner-container"
      style="justify-content: flex-start"
    >
      <span
        v-if="deficitHours.isDeficit"
        style="font-size: 20px"
      >Total Deficit Hours : {{ deficitHours.deficitHr }}</span>
      <span
        v-else
        style="font-size: 20px"
      >Total Extra Hours : {{ deficitHours.deficitHr }}</span>
    </div>
    <div
      v-if="totalAttendanceTypes !== null"
      class="register-inner-container"
      style="flex-direction: column; align-items: flex-start;"
    >
      <span style="font-size: 20px;">Attendance Types</span>
      <div class="att-summary">
        <div
          v-for="(attendanceType, key, index) in totalAttendanceTypes"
          :key="index"
          style="margin: 5px;"
          :style="{'font-size': '15px', 'padding': '5px', 'border-radius': '5px', 'background-color': attendanceType.color,}"
        >
          <span>
            {{ key }} :
          </span>
          <!-- Display the count and apply background color -->
          <span
            :style="{
              'font-size': '15px',
              // 'padding': '5px',
              'border-radius': '5px'
            }"
          >
            {{ attendanceType.attType }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="(isApprovalManager || isRO)"
      class="register-inner-container"
    >
      <div>
        Employee Type:
      </div>
      <EmployeeTypeFilter
        :current-folder="currentViewFolder"
        :register-view="isRegisterView"
        :filters-data="filtersData"
        :selected-emp-type="selectedEmployeeType"
        :on-grid-ready="onGridReady"
        :update-filter-values="updateFilterValues"
        @update-selected-employee-type="updateSelectedEmployeeType"
      />
    </div>
  </div>


  <div
    v-if="(isApprovalManager || isRO)"
    ref="tableContainer"
    class="register-container"
  >
    <table class="attstatus-table">
      <thead>
        <tr>
          <th>
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search by name or employee code"
              style="width: 100%;border: 1px, solid, black; padding: 1px"
            />
            <input
              v-model="showPending"
              type="checkbox"
              checked
              @change="togglePendingWork"
            />
            Pending
            <input
              v-model="showApproved"
              type="checkbox"
              @change="toggleApprovedWork"
            />
            Fully Approved
            <br>
            Employee Count: {{ filteredAttendanceSummary.length }}
          </th>
          <th>
            Unapproved
          </th>
          <th>
            Approved/<br>Settled
          </th>
        </tr>
      </thead>
      <tbody ref="tableContainerTbody">
        <tr
          v-for="(status, index) in filteredAttendanceSummary"
          :key="index"
          :class="{'selected-employee': selectedEmployee && selectedEmployee.id === status.id}"
        >
          <td
            class="summary_list"
            @click="filterEmp(status)"
          >
            {{ status.name }} ({{ status.empCode }}) - {{ status.empType }}
          </td>
          <td>{{ status.unapproved }}</td>
          <td>{{ status.final_approved }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { ref, computed, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import attendanceGridUtils from '../../../composables/attendanceGridUtils';
import EmployeeTypeFilter from './EmployeeTypeFilter.vue';

export default {
  name: 'RegisterSummary',
  components: { EmployeeTypeFilter },
  props: {
    currentFolder: {
      type: Object,
      default: () => {}
    },
    filtersData: {
      type: Object,
      default: () => {}
    },
    filteredEmp: {
      type: Object,
      default: () => {}
    },
    selectedEmp: {
      type: Object,
      default: () => {}
    },
    showPendingOnly: {
      type: Boolean,
      default: () => false
    },
    showApprovedOnly: {
      type: Boolean,
      default: () => false
    },
    onGridReady: {
      type: Function,
      default: () => {}
    },
    registerView: {
      type: Boolean,
      default: () => false
    },
    updateFilterValues: {
      type: Function,
      default: () => {}
    },
  },
  emits: ['update-search-query', 'update-selected-employee', 'update-selected-employee-type', 'toggle-pending-work', 'toggle-approved-work'],
  setup(props, { emit }) {
    const store = useStore();
    const bSettings = store.getters['bSettings'];
    let isApprovalManager = false;
    let isRO = false;
    const tableContainer = ref(null);
    const tableContainerTbody = ref(null);
    if (bSettings?.env?.code === 'admin' || bSettings?.env?.isHR) {
      isApprovalManager = true;
    } else if (bSettings?.env?.isAO) {
      isApprovalManager = true;
    }
    if (bSettings?.env?.isRO) {
      isRO = true;
    }
    const { deficitMinutesToHours } = attendanceGridUtils();
    const isRegisterView = computed(() => {
      return props.registerView;
    }
    );

    const attendanceRows = computed(() => {
      return store.getters['agGridModule/attendanceGridRows'];
    });

    const deficitHours = computed(() => {
      let deficitHr = 0;
      let isDeficit = null
      if (isRegisterView.value) {

        for (let i = 0; i < attendanceRows?.value?.length; i++) {
          if (attendanceRows.value[i].deficit_minutes !== undefined && attendanceRows.value[i].deficit_minutes !== null) {
            deficitHr += attendanceRows.value[i].deficit_minutes;
          }
        }
        if (deficitHr < 0) {
          isDeficit = true
        }
        else {
          isDeficit = false
        }
        deficitHr = deficitMinutesToHours(deficitHr, true);
      }
      return {deficitHr, isDeficit};
    });

    const workingHours = computed(() => {
      let workingHr = 0;
      if (isRegisterView.value) {
        for (let i = 0; i < attendanceRows?.value?.length; i++) {
          if (attendanceRows.value[i].working_minutes !== undefined && attendanceRows.value[i].working_minutes !== null) {
            if (attendanceRows.value[i].working_minutes != 0) {
              workingHr += attendanceRows.value[i].working_minutes;
            }
          }
        }
        workingHr = deficitMinutesToHours(workingHr, true);
      }
      return workingHr;
    });
    const totalAttendanceTypes = computed(() => {
      if (!isRegisterView.value) return null;

      const attendanceTypes = { 'Total Payable Days': { attType: 0, color: '' } };

      attendanceRows?.value?.forEach((row) => {
        const attType = row.att_type?.[0];
        let attNameEng = attType?.name?.eng || attType?.name;
        const leaveType = row.leave_type?.[0];
        if (['WO', 'LWP'].indexOf(leaveType?.code) > -1) {
          attNameEng = leaveType?.name?.eng || leaveType?.name ||  attNameEng;
        }
        const attCode = attType?.code;
        const isFinalApproved = row.attendance_status?.[0]?.code === 'final_approved';
        const isRejected = row.attendance_status?.[0]?.code === 'rejected';

        if (!attType || !attNameEng) return;

        // Determine the color based on attCode
        let color = '';
        if (attCode === 'P') {
          color = 'rgb(182 247 182)'; // Green
        } else if (attCode === 'A') {
          color = 'rgb(245 210 209)'; // Light Red
        } else if (attCode === 'H') {
          color = 'rgb(199 237 249)'; // Light Blue
        } else if (attCode === 'SP') {
          color = '#FFFFE0'; // Light Yellow
        } else if (attCode === 'PR') {
          color = '#D3D3D3'; // Light Gray
          if (isFinalApproved) {
            color = 'rgb(182 247 182)'; // Override with Green if final approved
          }
        } else {
          color = 'rgb(255 234 191)'; // Default Light Orange
        }

        // Increment attendance type count and assign color
        if (attCode === 'L') {
          if (row?.attendanceData !== undefined && row?.attendanceData !== null) {
            if (row?.attendanceData?.leave_type !== undefined && row?.attendanceData.leave_type !== null && row?.attendanceData.leave_type.length > 0) {
              if (row?.attendanceData?.leave_type?.[0].code === 'WO') {
                const leaveTypeName = row.attendanceData.leave_type[0].name.eng || row.attendanceData.leave_type[0].name;
                attNameEng = leaveTypeName;
              }
              if (row?.attendanceData?.leave_type?.[0].code === 'LWP') {
                const leaveTypeName = row.attendanceData.leave_type[0].name.eng || row.attendanceData.leave_type[0].name;
                attNameEng = leaveTypeName;
              }
            }
          }
        }
        if (!isRejected && attCode !== 'HD') {
          attendanceTypes[attNameEng] = attendanceTypes[attNameEng] || { attType: 0, color: '' };
          attendanceTypes[attNameEng].attType += 1;
          attendanceTypes[attNameEng].color = color;

        }

        if (attCode === 'HD' && !isRejected) {
          if (row?.attendanceData?.leave_type !== undefined && row?.attendanceData?.leave_type !== null && row?.attendanceData?.leave_type?.length > 0) {
            if (row?.attendanceData?.working_minutes != 0) {
              attendanceTypes['Present'] = attendanceTypes['Present'] || { attType: 0, color: 'rgb(182 247 182)' };
              attendanceTypes['Present'].attType += 0.5;
              attendanceTypes['Leave'] = attendanceTypes['Leave'] || { attType: 0, color: 'rgb(255 234 191)' };
              attendanceTypes['Leave'].attType += 0.5;
            }
            else {
              attendanceTypes['Leave'] = attendanceTypes['Leave'] || { attType: 0, color: 'rgb(255 234 191)' };
              attendanceTypes['Leave'].attType += 0.5;
            }
          } else {
            attendanceTypes['Present'] = attendanceTypes['Present'] || { attType: 0, color: 'rgb(182 247 182)' };
            attendanceTypes['Present'].attType += 0.5;
          }
        }
        // if (attCode === 'WO' && !isRejected) {
        //   if (row?.attendanceData?.leave_type !== undefined && row?.attendanceData?.leave_type !== null && row?.attendanceData?.leave_type.length > 0) {
        //     attendanceTypes['Leave '] = attendanceTypes['Leave'] || { attType: 0, color: 'rgb(255 234 191)' };
        //     attendanceTypes['Leave'].attType += 1;
        //     attendanceTypes['Weekly Off'].attType -= 1;
        //   }
        // }

        // Increment total payable days
        if (['P', 'WO', 'R', 'L', 'H', 'T', 'HD'].includes(attCode)) {
          if (!isRejected) {
            if (attCode === 'HD') {
              if (row?.attendanceData?.leave_type !== undefined && row?.attendanceData?.leave_type !== null && row?.attendanceData?.leave_type.length > 0) {
                if (row?.attendanceData?.working_minutes != 0) {
                  attendanceTypes['Total Payable Days'].attType += 1;
                } else {
                  attendanceTypes['Total Payable Days'].attType += 0.5;
                }
              } else {
                attendanceTypes['Total Payable Days'].attType += 0.5;
              }
            } else if (attCode === 'L') {
              if (row?.attendanceData?.leave_type !== undefined && row?.attendanceData?.leave_type !== null && row?.attendanceData?.leave_type.length > 0) {
                if (row?.attendanceData?.leave_type?.[0].code !== 'LWP') {
                  attendanceTypes['Total Payable Days'].attType += 1;
                }
              }
            } else {
              attendanceTypes['Total Payable Days'].attType += 1;
            }
          }
        }
      });

      return attendanceTypes;
    });

    const searchQuery = ref('');

    const showPendingOnly = computed(() => {
      return props.showPendingOnly;
    })
    const showPending = ref(showPendingOnly.value);

    const showApprovedOnly = computed(() => {
      return props.showApprovedOnly;
    })
    const showApproved = ref(showApprovedOnly.value);

    function togglePendingWork() {
      showApproved.value = false;
      emit('toggle-pending-work', !showPendingOnly.value, showApproved.value);
    }

    function updateSelectedEmployeeType(employeeType) {
      console.log('RegisterSummary: updateSelectedEmployeeType')
      selectedEmployeeType.value = employeeType
      emit('update-selected-employee-type', employeeType)
    }

    function toggleApprovedWork() {
      emit('toggle-approved-work', !showApprovedOnly.value, showPending.value);
    }

    const filteredAttendanceSummary = computed(() => {
      return props.filteredEmp;

    });

    const selectedEmployeeType = ref()

    const selectedEmployee = computed(() => {
      return props.selectedEmp;
    })


    function filterEmp(emp) {
      const selectedValue = {
        label: `${emp.name} (${emp.empCode}) - ${emp.empType}`,
        id: emp.id,
        type: emp.empType
      }

      emit('update-selected-employee', selectedValue);
      nextTick(() => {
        scrollToSelectedEmployee();
      });
    }

    function scrollToSelectedEmployee() {
      // Find the row in the table
      if (tableContainer.value !== null && tableContainer.value !== undefined) {
        const row = Array.from(tableContainer.value.querySelectorAll('.attstatus-table tbody tr'))
          .find((r) => r.classList.contains('selected-employee'));
        const tbody = Array.from(tableContainer.value.querySelectorAll('.attstatus-table tbody'))[0]
        const thead = Array.from(tableContainer.value.querySelectorAll('.attstatus-table thead'))[0]
        if (row) {
          //let topPos = row.offsetTop
          //if (topPos > tableContainer.value.scrollTop) {
          //  tableContainer.value.scrollTop = topPos - thead.offsetHeight
          //}
          //else {
          //  tableContainer.value.scrollTop = topPos - row.offsetHeight
          //}
          row.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }

    watch(selectedEmployee, (newEmployee) => {
      if (newEmployee && newEmployee.id) {
        scrollToSelectedEmployee();
      }
    });

    watch(searchQuery, (newQuery) => {
      emit('update-search-query', newQuery);
    });

    return {
      isApprovalManager,
      isRO,

      //registerView
      deficitHours,
      workingHours,
      totalAttendanceTypes,
      togglePendingWork,
      showPending,
      toggleApprovedWork,
      showApproved,
      searchQuery,
      filteredAttendanceSummary,
      filterEmp,
      selectedEmployee,
      selectedEmployeeType,
      updateSelectedEmployeeType,
      tableContainer

    }
  }
}
</script>

<style>

  /* Highlighting the selected employee in the summary list */
  .selected-employee {
    background-color: #e6f7ff;
    font-weight: bold;
  }


  .register-filter {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
    background-color: #ffffff;
    color: #292929;
  }

  .register-inner-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 2px;
    padding: 2px;
  }

  .att-summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    height: auto;
    width: auto;
  }

  .register-inner-container span {
    font-size:14px;
    margin: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .filter-select {
    font-size: 13px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  .selected_option {
    font-weight: 700;
    color:green;
  }
  .selected_option :hover {
    color:white;
  }

  .register-container {
    color: #292929;
    max-height: 500px; /* Set the maximum height of the table container */
    overflow-y: auto;  /* Enable vertical scrolling */
    border: 1px solid black; /* Add border around the scrollable area */
  }

  .attstatus-table {
    width: 100%;
    border-collapse: collapse;
  }

  .attstatus-table th,
  .attstatus-table td {
    border: 1px solid black; /* Add border to table headers and cells */
    padding: 8px;
    text-align: left;
  }

  .attstatus-table th {
    background-color: #f2f2f2; /* Optional: Add background color to header */
    font-weight: bold;
    position: sticky; /* Keep the header fixed */
    top: 0; /* Fix the header to the top */
    z-index: 1; /* Ensure the header stays above the table rows */
  }

  .summary_list {
    cursor: pointer;
  }

  .summary_list:hover {
    color: #83ACC8;
  }

</style>
