//composable to keep projections for folders in one place
const getProjections = () => {
  const projectionsList = {
    'tasks': {
      'tasks': ['id', 'name', 'code', 'time_slot', 'scheduled', 'departments_id', 'subsections_id'],
      'tasks.departments_id': ['id', 'name', 'code'],
      'tasks.subsections_id': ['id', 'name', 'code'],
    },
    'payslips':{
      'payslips': ['uconst', 'pay_period', 'employees_id', 'aggregate_pay_details', 'payroll_month', 'working_days', 'itemized_pay_details', 'status'],
      'payslips.employees_id': ['persons_id', 'departments', 'designation', 'subsections'],
      'payslips.status':['code', 'id', 'name'],
      'payslips.employees_id.departments':['id'],
      'payslips.employees_id.designation':['id'],
      'payslips.employees_id.subsections':['id', 'code', 'name'],
      'payslips.employees_id.persons_id':['first_name', 'uan', 'pan', 'esic_number'],
      'payslips.aggregate_pay_details':['amount', 'payhead_id', 'payhead_types', 'payable_days', 'overtime_hrs'],
      'payslips.aggregate_pay_details.payhead_id':['code'],
      'payslips.aggregate_pay_details.payhead_types':['code'],
      'payslips.itemized_pay_details':['sites_id'],
      'payslips.itemized_pay_details.sites_id':['code', 'name']
    },
    'leaves': {
      'leaves': ['id', 'creation_date', 'employees_id', 'leave_days', 'txn_state', 'notes', 'deleted'],
      'leaves.employees_id': ['id', 'code', 'deleted', 'persons_id'],
      'leaves.txn_state':['executed_by', 'last_executed', 'txn_name', 'leaves_id'],
      'leaves.employees_id.persons_id': ['id', 'deleted', 'last_name', 'first_name', 'middle_name'],
      'leaves.leave_days':['id', 'leaves_id', 'leave_types_id', 'leave_period', 'leave_minutes', 'leave_status', 'half_day_duration', 'deleted', 'documents'],
      'leaves.leave_days.leave_types_id':[ 'id', 'code', 'name'],
      'leaves.leave_days.documents':['id', 'filepath'],
      'leaves.leave_days.half_day_duration':['id', 'code', 'name'],
      'leaves.leave_days.leave_status':[ 'id', 'code', 'name', 'deleted', 'creation_date']
    },

    'attendance':{
      'attendance': ['uconst', 'deleted', 'creation_date', 'last_modified', 'attendance_date', 'working_minutes', 'overtime_minutes', 'employees_id', 'allocations_id',
        'sites_id', 'shifts_id', 'att_type', 'designation', 'attendance_status', 'observed_in_tmstmp', 'observed_out_tmstmp', 'last_processed_by', 'exception', 'leave_type', 'deficit_minutes'],
      'attendance.last_processed_by': ['id', 'code'],
      'attendance.allocations_id': ['id'],
      'attendance.attendance_status': ['id', 'code', 'name',  'deleted', 'last_modified'],
      'attendance.shifts_id': ['id', 'name', 'shift_code', 'shift_time', 'effective_to', 'creation_date', 'last_modified'],
      'attendance.designation': ['id', 'code', 'name', 'price', 'deleted', 'creation_date', 'last_modified'],
      'attendance.att_type': ['id', 'code', 'name', 'deleted', 'last_modified'],
      'attendance.leave_type': ['id', 'code', 'name',  'deleted', 'last_modified'],
      'attendance.sites_id': ['id', 'code', 'city', 'name', 'email', 'gstin', 'address', 'deleted', 'pincode', 'swhandle', 'site_code', 'last_modified'],
      'attendance.employees_id': ['id', 'code', 'deleted', 'persons_id', 'working_hours', 'working_days', 'departments', 'subsections', 'employee_type'],
      'attendance.employees_id.employee_type': ['id', 'code', 'name'],
      'attendance.employees_id.departments': ['id', 'code', 'name'],
      'attendance.employees_id.subsections': ['id', 'code', 'name'],
      'attendance.employees_id.persons_id': ['id', 'deleted', 'last_name', 'birth_date', 'first_name', 'middle_name', 'gender'],
      'attendance.employees_id.persons_id.gender': ['id', 'code', 'name',  'deleted', 'last_modified'],
      'attendance.exception': ['id', 'att_type'],
      'attendance.exception.att_type': ['id', 'code', 'name', 'deleted', 'last_modified'],
    },
    'allocations': {
      'allocations':['id', 'allocations_period', 'contracts_id', 'employees_id', 'products_id', 'sites_id', 'last_modified', 'created_by', 'deleted'],
      'allocations.contracts_id': ['id', 'attendance_based_items', 'contract_type', 'job_based_items', 'date_of_previous_month', 'name'],
      'allocations.employees_id': ['id', 'code', 'deleted', 'persons_id', 'departments', 'designation', 'duty_chart_officer', 'approving_officer', 'reporting_officer'],
      'allocations.employees_id.designation': ['id', 'code', 'name', 'price', 'deleted', 'creation_date', 'last_modified'],
      'allocations.employees_id.persons_id': ['id', 'deleted', 'last_name', 'birth_date', 'first_name', 'middle_name', 'gender'],
      'allocations.employees_id.persons_id.gender': ['id', 'code', 'name', 'source', 'deleted', 'last_modified'],
      'allocations.employees_id.duty_chart_officer': ['id'],
      'allocations.employees_id.approving_officer': ['id'],
      'allocations.employees_id.reporting_officer': ['id'],
      'allocations.sites_id': ['id', 'code', 'city', 'name', 'email', 'gstin', 'address', 'deleted', 'pincode', 'swhandle', 'site_code', 'last_modified'],
      'allocations.products_id': ['id', 'code', 'name', 'price', 'deleted', 'creation_date', 'last_modified'],
      'allocations.allocations_period': ['allocations_id', 'allocations_range', 'deleted', 'id', 'last_modified', 'shifts_id', 'allocation_type', 'departments_id'],
      'allocations.allocations_period.shifts_id': ['id', 'name', 'shift_code', 'shift_time', 'effective_to', 'creation_date', 'last_modified'],
      'allocations.allocations_period.allocation_type': ['id', 'name', 'code', 'shift_time', 'creation_date', 'last_modified'],
      'allocations.employees_id.departments': ['id', 'code', 'name', 'deleted', 'creation_date', 'last_modified'],
      'allocations.allocations_period.departments_id': ['id', 'code', 'name', 'deleted', 'creation_date', 'last_modified'],
      'allocations.created_by': ['id', 'code', 'deleted', 'persons_id'],
      'allocations.created_by.persons_id': ['id', 'deleted', 'last_name', 'first_name', 'middle_name'],
      'allocations.contracts_id.job_based_items': ['id'],
      'allocations.contracts_id.contract_type': ['id', 'code', 'name'],
      'allocations.contracts_id.attendance_based_items': ['id']
    },
    'subsections':{
      'subsections':[ 'id', 'code', 'name', 'deleted', 'departments', 'partners_id', 'creation_date', 'last_modified', 'subsection_head', 'approving_officer', 'reporting_officer', 'duty_chart_officer'],
      'subsections.departments':[ 'id', 'code', 'name', 'deleted', 'partners_id', 'creation_date', 'last_modified'],
      'subsections.subsection_head':['id', 'code', 'persons_id', 'deleted'],
      'subsections.subsection_head.persons_id':[  'id', 'deleted', 'last_name', 'first_name', 'partners_id'],
      'subsections.approving_officer':['id', 'code', 'persons_id', 'deleted'],
      'subsections.approving_officer.persons_id':[  'id', 'deleted', 'last_name', 'first_name', 'partners_id'],
      'subsections.reporting_officer':['id', 'code', 'persons_id', 'deleted'],
      'subsections.reporting_officer.persons_id':[  'id', 'deleted', 'last_name', 'first_name', 'partners_id'],
      'subsections.duty_chart_officer':['id', 'code', 'persons_id', 'deleted'],
      'subsections.duty_chart_officer.persons_id':[  'id', 'deleted', 'last_name', 'first_name', 'partners_id'],

    },
    'employees':{},

    //list the folder with thier projections
  }
  function projectionForFolder(folderName) {
    return projectionsList[folderName] ? projectionsList[folderName] : {}
  }

  return { projectionForFolder };
};

export default getProjections;
